import React from 'react';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';

export default (props) => {
    return (
        <>
            <div className="form-control">
                <div className="k-edit-label">
                    <label> Alert Name:</label>
                </div>
                <div className="k-edit-field">
                    <Input
                        style={{ width: '250px' }}
                        name="subscriptionName"
                        value={props.data.subscriptionName}
                        type="text" onChange={props.actions.inputChange} />
                </div>
            </div>
            {props.children}

            <div className="form-control">
                <div className="k-edit-label">
                    <label>Alert Between:</label>
                </div>
                <div className="k-edit-field">
                    <TimePicker
                        value={props.data.alertStart}
                        width={150}
                        name="alertStart"
                        onChange={props.actions.inputChange} />

                    <TimePicker
                        width={150}
                        name="alertEnd"
                        value={props.data.alertEnd}
                        onChange={props.actions.inputChange} />
                </div>
            </div>
            <div className="form-control">
                <div className="k-edit-label">
                    <label>Alert Days:</label>
                </div>
                <div className="k-edit-field alert-checkbox">
                    <label>
                        Mon
                        <input
                            type="checkbox"
                            name="editMon"
                            onChange={props.actions.onAlertDayChange}
                            checked={props.data.editMon} />
                    </label>
                    <label>
                        Tues
                        <input
                            type="checkbox"
                            name="editTues"
                            onChange={props.actions.onAlertDayChange}
                            checked={props.data.editTues} />
                    </label>
                    <label>
                        Weds
                        <input
                            type="checkbox"
                            name="editWeds"
                            onChange={props.actions.onAlertDayChange}
                            checked={props.data.editWeds} />
                    </label>
                    <label>
                        Thurs
                        <input
                            type="checkbox"
                            name="editThurs"
                            onChange={props.actions.onAlertDayChange}
                            checked={props.data.editThurs}
                            data-bind="checked:editThurs" />
                    </label>
                    <label>
                        Fri
                        <input
                            type="checkbox"
                            name="editFri"
                            onChange={props.actions.onAlertDayChange}
                            checked={props.data.editFri} />
                    </label>
                    <label>
                        Sat
                        <input
                            type="checkbox"
                            name="editSat"
                            onChange={props.actions.onAlertDayChange}
                            checked={props.data.editSat} />
                    </label>
                    <label>
                        Sun
                        <input
                            type="checkbox"
                            name="editSun"
                            onChange={props.actions.onAlertDayChange}
                            checked={props.data.editSun} />
                    </label>
                </div>
            </div>
            <div className="form-control">
                <div className="k-edit-label">
                    <label>Notification Method:</label>
                </div>
                <div className="k-edit-field">
                    <label>
                        Email
                        <input
                            type="checkbox"
                            name="emailNotification"
                            checked={props.data.emailNotification}
                            onChange={props.actions.notificationTypeChange} />
                    </label>
                    {props.subscriptionTypeId !== 13 &&
                        <label>
                            Portal
                            <input
                                type="checkbox"
                                name="portalNotification"
                                checked={props.data.portalNotification}
                                onChange={props.actions.notificationTypeChange} />
                        </label>}
                    {props.alertConfigData.smsSettings.smsAccountID !== ''
                        && <label>
                            SMS
                                {(props.alertConfigData.smsSettings.smsPhoneNo === '' || props.alertConfigData.smsSettings.smsPhoneNo.length < 10) &&
                                <input type="checkbox"
                                    name="smsNotification"
                                    checked={props.data.smsNotification}
                                    onChange={props.actions.notificationTypeChange}
                                    disabled={true} />
                            }
                            {(props.alertConfigData.smsSettings.smsPhoneNo !== '' || props.alertConfigData.smsSettings.smsPhoneNo.length > 9) &&
                                <input type="checkbox"
                                    name="smsNotification"
                                    checked={props.data.smsNotification}
                                    onChange={props.actions.notificationTypeChange} />
                            }
                        </label>
                    }

                </div>
            </div>
            {props.RSS}
            <div className="form-control">
                <div className="k-edit-label">
                    <label>Alert Active:</label>
                </div>
                <div className="k-edit-field">
                    <label>
                        Active
                        <input
                            type="radio"
                            checked={props.data.isActive}
                            name="isActive"
                            onChange={props.actions.activeStatusChange} />
                    </label>
                    <label>
                        Inactive
                        <input
                            type="radio"
                            checked={!props.data.isActive}
                            name="isActive"
                            onChange={props.actions.activeStatusChange} />
                    </label>
                </div>
            </div>
        </>
    );
}